import React, { useState, useEffect } from "react";
import { useToast } from "../../Toast";
import HeaderMenu from "../headerMenu";
import SideBar from "../SideBar";
import { Card, Container, Row, Col } from "reactstrap";
import BreadCrumb from "../../plugins/Breadcrumb";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

const NotifySettings = (props) => {
  const location = useLocation();
  const settingsData = location.state?.settingsData;
  const userId = location.state?.userId;

  const [notifyFeatures, setNotifyFeatures] = useState([]);
  const [userNotify, setUserNotify] = useState([]);
  const [channelList, setChannelList] = useState([]);
  const [updatedNotifications, setUpdatedNotifications] = useState([]);

  const navigate = useNavigate();
  const toast = useToast();

  const handleCheckboxChange = (featureId, channelId, isChecked) => {
    setUpdatedNotifications((prevState) => {
      const existingIndex = prevState.findIndex(
        (item) => item.featureId === featureId && item.channelId === channelId
      );

      if (isChecked) {
        if (existingIndex === -1) {
          return [
            ...prevState,
            {
              userId: userId,
              featureId: featureId,
              channelId: channelId,
              isEnabled: true,
            },
          ];
        } else {
          const updatedState = [...prevState];
          updatedState[existingIndex].isEnabled = true;
          return updatedState;
        }
      } else {
        if (existingIndex !== -1) {
          const updatedState = [...prevState];
          updatedState[existingIndex].isEnabled = false;
          return updatedState;
        }
        return prevState;
      }
    });
  };

  useEffect(() => {
    getUserNotifyList();
    getNotifyChannelList();
  }, [settingsData, userId]);

  useEffect(() => {
    // if (userNotify?.length > 0) {
    loadNotificationFeatureList();
    // }
  }, []);

  const loadNotificationFeatureList = async () => {
    try {
      const response = await window.apiService.userNotificationFeatureList();
      const features = response.data.recordInfo;
      setNotifyFeatures(features);
    } catch (error) {
      toast.error("Error Occurred");
    }
  };

  const getUserNotifyList = async () => {
    if (userId) {
      try {
        const response = await window.apiService.userNotificationList(userId);
        setUserNotify(response.data.recordInfo);
      } catch (error) {
        toast.error("Error Occurred");
      }
    }
  };

  const getNotifyChannelList = async () => {
    try {
      const response = await window.apiService.notifyChannelList();
      setChannelList(response.data.recordInfo);
    } catch (error) {
      toast.error("Error Occurred");
    }
  };

  const handleSubmit = async () => {
    const finalNotifications = notifyFeatures.flatMap((feature) =>
      channelList.map((channel) => {
        const existingRecord = userNotify.find(
          (notification) =>
            notification.featureId === feature.id && notification.channelId === channel.id
        );

        const updatedRecord = updatedNotifications.find(
          (update) =>
            update.featureId === feature.id && update.channelId === channel.id
        );

        return {
          userId: userId,
          featureId: feature.id,
          channelId: channel.id,
          isEnabled: updatedRecord ? updatedRecord.isEnabled : existingRecord?.enabled || false,
        };
      })
    );

    const enabledNotifications = finalNotifications.filter((notification) => notification.isEnabled);

    try {
      const response = await window.apiService.updateNotifyPref(enabledNotifications);
      if (response.data.status === "OK") {
        toast.success(response.data.message);
        getUserNotifyList();
      } else {
        toast.error("Error Occurred");
      }
    } catch (error) {
      toast.error("Error Occurred");
    }
  };

  useEffect(() => {
    if (userNotify.length > 0) {
      // Initialize updatedNotifications with the userNotify data on initial load
      const initialUpdatedNotifications = userNotify.map((notification) => ({
        userId: userId,
        featureId: notification.featureId,
        channelId: notification.channelId,
        isEnabled: notification.enabled || false,
      }));
      setUpdatedNotifications(initialUpdatedNotifications);
    }
  }, [userNotify, userId]);

  const mergedNotificationData = notifyFeatures.map((row) => {
    const updatedRow = {
      id: row.id,
      name: row.name,
    };

    channelList.forEach((channel) => {
      const channelKey = channel.name.toLowerCase().replace(" ", "_");
      updatedRow[channelKey] = false;
    });
    const allNotifications = [
      ...userNotify.filter((n) => n.featureId === row.id),
      ...updatedNotifications.filter((n) => n.featureId === row.id),
    ];

    allNotifications.forEach((notification) => {
      const channelKey = channelList.find(
        (channel) => channel.id === notification.channelId
      )?.name.toLowerCase().replace(" ", "_");

      if (channelKey) {
        updatedRow[channelKey] = notification.isEnabled || notification.enabled;
      }
    });
    const inAppChannelKey = channelList.find(
      (channel) => channel.name.toLowerCase() === "in app"
    )?.name.toLowerCase().replace(" ", "_");

    if (inAppChannelKey) {
      updatedRow[inAppChannelKey] = row.isDefault;
    }

    return updatedRow;
  });

  return (
    <>
      <HeaderMenu />
      <SideBar />
      <div className="main-content ml_200">
        <Card className="shade h-500">
          <Container fluid>
            <div className="header-body pt-3">
              <div className="d-flex mt-2 toggle_gap">
                <h6 className="font-weight-500">
                  Choose the notification preference
                </h6>
              </div>
              <Row className="justify-content-center">
                <div className="col-lg-9 col-md-9 col-sm-12">
                  <div class="notification-container">
                    <div class="notification-header">
                      <div className="header">Feature</div>
                      {channelList.map((channel) => (
                        <div className="header" key={channel.id}>{channel.name}</div>
                      ))}
                    </div>
                    <div className="notification-grid">
                      {mergedNotificationData.map((row, index) => (
                        <React.Fragment key={index}>
                          <div className="cell">{row.name}</div>
                          {channelList.map((channel) => {
                            const normalizedKey = channel.name.toLowerCase().replace(" ", "_");
                            const isInAppChannel = channel.name.toLowerCase() === "in app";
                            return (
                              <div className="cell" key={`${row.name}-${channel.name}`}>
                                <input
                                  type="checkbox"
                                  checked={row[normalizedKey] || false}
                                  onChange={(e) => {
                                    if (!isInAppChannel) {
                                      handleCheckboxChange(row.id, channel.id, e.target.checked);
                                    }
                                  }}
                                  disabled={isInAppChannel}
                                />
                              </div>
                            );
                          })}
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                </div>
              </Row>
              <Row className="justify-content-center">
                <Col lg="9" xl="9" md="9" sm="12">
                  <div className="ml-2 mr-2 text-end">
                    <button
                      type="submit"
                      className="btn btn-primary rev_btn mb-2"
                      onClick={handleSubmit}
                    >
                      Update
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </Card>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    emailVerified: state.user.emailVerified,
    phoneVerified: state.user.phoneVerified,
  };
};

export default connect(mapStateToProps)(NotifySettings);
