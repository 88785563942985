import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userAction } from "../Redux/Actions/userAction";
import { useAuth } from './AuthContext';
import { useSubscription } from '../components/SubscriptionContext';
const Refresh = ({ children }) => {
  const dispatch = useDispatch();
  const authToken = useSelector((state) => state.user.token);
  const userType = localStorage.getItem("userType");
  const switchAccount = localStorage.getItem("switchAccount");
  const profileId = localStorage.getItem("profileId");
  const { login } = useAuth();
  const { refreshSubscriptions } = useSubscription();
  useEffect(() => {
    const fetchData = async () => {
      if (authToken) {
        const params = {
          profileId: profileId == "undefined" ? null : profileId,
          authToken: authToken,
          userType: userType ? userType : "Personal",
          switchAccount: switchAccount ? switchAccount : false,
        };
        try {
          const data = await window.apiService.refresh(params);
          let result = data.data;
          if (data) {
            dispatch(userAction(result));
          }
          login(result.userId);
          refreshSubscriptions();
        } catch (error) {
          console.log(error);
        }
      }
    };
    fetchData();
  }, []);

  return <>{children}</>;
};

export default Refresh;
