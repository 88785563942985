import React from "react";
import { RiUserSettingsFill } from "react-icons/ri";
import SideBar from "./AdminSideBar";
import HeaderMenu from "./HeaderMenu";
import BreadCrumb from "../plugins/Breadcrumb";
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { FaUsersViewfinder } from "react-icons/fa6";
import { TbCoinFilled } from "react-icons/tb";
import { RiCheckboxMultipleFill } from "react-icons/ri";
const Settings = () => {
  const navigate = useNavigate();
  return (
    <>
      <HeaderMenu />
      <SideBar />
      <div className="main-content ml_200">
        {/* <BreadCrumb /> */}
        <Card className="shade h-500">
          <Container fluid>
            <div className="header-body  pt-3">
              {/* Card stats */}
              <Row>
                <Col lg="6" xl="3">
                  <Card
                    className="card-stats mb-4 mb-xl-0"
                    onClick={() => {
                      navigate("/dashboard/settings/professional_types");
                    }}
                  >
                    <CardBody className="setngLst">
                      <Row>
                        <Col className="col-auto pad_lf">
                          {/* <div className="icon icon-shape bg-danger text-white rounded-circle shadow"> */}
                          <RiUserSettingsFill style={{ fontSize: "25px" }} />
                          {/* </div> */}
                        </Col>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="fs_14px text-uppercase text-muted mb-0"
                          >
                            Manage Professional Types & Mapping
                          </CardTitle>
                        </div>
                        {/* <Col className="col-auto pad_lf">
                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                          <RiUserSettingsFill />
                        </div>
                      </Col> */}
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="3">
                  <Card
                    className="card-stats mb-4 mb-xl-0"
                    onClick={() => {
                      navigate("/dashboard/settings/Score_settings");
                    }}
                  >
                    <CardBody className="setngLst">
                      <Row>
                        <Col className="col-auto pad_lf">
                          {/* <div className="icon icon-shape bg-danger text-white rounded-circle shadow"> */}
                          <FaUsersViewfinder style={{ fontSize: "25px" }} />
                          {/* </div> */}
                        </Col>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="fs_14px text-uppercase text-muted mb-0"
                          >
                            Manage Composite Score Factors
                          </CardTitle>
                        </div>
                        {/* <Col className="col-auto pad_lf">
                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                          <RiUserSettingsFill />
                        </div>
                      </Col> */}
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="3">
                  <Card
                    className="card-stats mb-4 mb-xl-0"
                    onClick={() => {
                      navigate("/dashboard/settings/plans");
                    }}
                  >
                    <CardBody className="setngLst">
                      <Row>
                        <Col className="col-auto pad_lf">
                          <TbCoinFilled style={{ fontSize: "25px" }} />
                        </Col>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="fs_14px text-uppercase text-muted mb-0"
                          >
                            Manage Service Plans & Features
                          </CardTitle>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="3">
                  <Card
                    className="card-stats mb-4 mb-xl-0"
                    onClick={() => {
                      navigate("/admin/settings/inapp");
                    }}
                  >
                    <CardBody className="setngLst">
                      <Row>
                        <Col className="col-auto pad_lf">
                          <RiCheckboxMultipleFill style={{ fontSize: "25px" }} />
                        </Col>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="fs_14px text-uppercase text-muted mb-0"
                          >
                            Manage In App Features
                          </CardTitle>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </Card>
      </div>
    </>
  );
};

export default Settings;
