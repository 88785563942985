const local_storage_name = process.env.REACT_APP_LOCALSTORAGE_NAME;

const initialState = {
  userId: "",
  token: localStorage.getItem(local_storage_name) || "",
  userName: "",
  imagePath: "",
  userType: "",
  selectedTypeId: "",
  followid: "",
  followUserType: "",
  followProfileId: "",
  revId: "",
  phNumber: "",
  newReviewCount: "",
  newInboxCount: "",
  groupNotify: "",
  newMentionCount: "",
  id: "",
  emailVerified: "",
  phoneVerified: "",
  // userTypeInfo: "",
  userTypeInfo: [],
  email: "",
  phone: "",
  profileId: "",
  // busnAdminId: "",
  busnAdminId: null,
  isKycVerified: "",
  isAuthenticating: true,
  notifications: [],
  reviewNotification: [],
  notificationCount: 0,
  mentionnotification: [],
  planNotifyCount:""
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SAVE_USER_DATA":
      if (action.payload.token) {
        localStorage.setItem(local_storage_name, action.payload.token);
      }
      // Safely accessing userTypeInfo and ensuring it's an array
      const userTypeInfo = Array.isArray(action.payload.userTypeInfo) ? action.payload.userTypeInfo : [];

      // Find business admin ID safely
      const busnAdmin = userTypeInfo.find(
        (userType) =>
          userType.userTypeName === "Business" &&
          userType.businessId === action.payload.profileId
      );

      return {
        ...state,
        token: action.payload.token,
        userId: action.payload.userId,
        userName: action.payload.userName,
        imagePath: action.payload.imagePath,
        userType: action.payload.userType,
        newReviewCount: action.payload.newReviewCount,
        newInboxCount: action.payload.newInboxCount,
        groupNotify: action.payload.groupNotify,
        newMentionCount: action.payload.newMentionCount,
        emailVerified: action.payload.emailVerified,
        phoneVerified: action.payload.phoneVerified,
        // userTypeInfo: action.payload.userTypeInfo,
        userTypeInfo: userTypeInfo,
        email: action.payload.email,
        // phone: action.payload.phone != undefined ? action.payload.phone : "",
        phone: action.payload.phone || "",
        profileId: action.payload.profileId,
        isKycVerified: action.payload.isKycVerified,
        planNotifyCount: action.payload.planNotifyCount,
        // busnAdminId:
        //   (
        //     action.payload.userTypeInfo.find(
        //       (userType) =>
        //         userType.userTypeName === "Business" &&
        //         userType.businessId === action.payload.profileId
        //     ) || {}
        //   ).busnAdminId || null,
        busnAdminId: busnAdmin ? busnAdmin.busnAdminId : null,
        isAddUserInApp: action.payload.isAddUserInApp,
        isInboxInApp: action.payload.isInboxInApp,
        isInviteUserInApp: action.payload.isInviteUserInApp,
        isMentionCommentInApp: action.payload.isMentionCommentInApp,
        isPlanExpInApp: action.payload.isPlanExpInApp,
        isProfileUpdateInApp: action.payload.isProfileUpdateInApp,
        isReviewCommentInApp: action.payload.isReviewCommentInApp,
        isReviewInApp: action.payload.isReviewInApp,
        isTrialExpInApp: action.payload.isTrialExpInApp
      };
    case "LOGOUT":
      localStorage.removeItem(local_storage_name);
      localStorage.removeItem("userType");
      localStorage.removeItem("switchAccount");
      localStorage.removeItem("profileId");
      localStorage.removeItem("notified");
      return { ...initialState };
    case "USER_TYPE_ID":
      return {
        ...state,
        selectedTypeId: action.payload.selectedTypeId,
      };
    case "FOLLOW_ID":
      return {
        ...state,
        followid: action.payload.followid,
        followUserType: action.payload.userType,
        followProfileId: action.payload.profileId,
      };
    case "REV_ID":
      return {
        ...state,
        revId: action.payload.revId,
      };
    case "PH_NUMBER":
      return {
        ...state,
        phNumber: action.payload.phNumber,
      };
    case "NOT_REV_INFO":
      return { ...state, id: action.payload.id };
    case "USER_AUTH":
      return { ...state, isAuthenticating: action.payload.isAuthenticating };
    case 'SET_NOTIFICATION':
      return {
        ...state,
        notifications: [...state.notifications, action.payload],
      };
    case 'CLEAR_NOTIFICATIONS':
      return {
        ...state,
        notifications: [],
      };

    case 'CLEAR_REVIEW_NOTIFICATIONS':
      return {
        ...state,
        reviewnotifications: [],
      };
    case 'INCREMENT_NOTIFICATION_COUNT':
      return {
        ...state,
        notificationCount: state.notificationCount + 1,
      };
    case 'RESET_NOTIFICATION_COUNT':
      return {
        ...state,
        notificationCount: 0,
      };


    case 'SET_MENTION_NOTIFICATION':
      return {
        ...state,
        mentionnotification: [...state.mentionnotification, action.payload],
      };

    case 'RESET_MENTION_NOTIFICATION_COUNT':
      return {
        ...state,
        mentionnotification: [],
      };

    case 'REMOVE_MENTION_NOTIFICATION':
      return {
        ...state,
        mentionnotification: state.mentionnotification.filter((_, index) => index !== action.payload),
      };

    default:
      return state;
  }
};

export default userReducer;
