import React, { useState, useEffect } from "react";
import { useToast } from "../../Toast";
import SideBar from "../AdminSideBar";
import HeaderMenu from "../HeaderMenu";
import { Card, Col, Row, Container, Button } from "react-bootstrap";

const InAppFeatures = () => {
    const [notifyFeatures, setNotifyFeatures] = useState([]);
    const toast = useToast();

    useEffect(() => {
        loadNotificationFeatureList();
    }, []);

    const loadNotificationFeatureList = async () => {
        try {
            const response = await window.apiService.userNotificationFeatureList();
            const features = response.data.recordInfo;
            setNotifyFeatures(features);
        } catch (error) {
            toast.error("Error Occurred");
        }
    };

    const updateNotifyFeatureList = async () => {
        if (notifyFeatures.length > 0) {
            try {
                const response = await window.apiService.updateNotifyFeatures(notifyFeatures);
                const result = response.data;
                if (result.status == "OK") {
                    loadNotificationFeatureList();
                    toast.success(result.message);
                }
            } catch (error) {
                toast.error("Error Occurred");
            }
        }
    };

    const handleCheckboxChange = (index, checked) => {
        const updatedFeatures = [...notifyFeatures];
        updatedFeatures[index].isDefault = checked;
        setNotifyFeatures(updatedFeatures);
    };

    return (
        <>
            <HeaderMenu />
            <SideBar />
            <div className="main-content ml_200">
                <Card className="shade h-500">
                    <Container fluid>
                        <Row>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="notification-container m-2">
                                    <div className="notification-header two_grid_column">
                                        <div className="header">Feature</div>
                                        <div className="header">Is Default</div>
                                    </div>
                                    {notifyFeatures && notifyFeatures.length > 0 ? (
                                        <div className="notification-grid two_grid_column h-220">
                                            {notifyFeatures.map((item, index) => (
                                                <React.Fragment key={item.id}>
                                                    <div className="cell">{item.name}</div>
                                                    <div className="cell">
                                                        <input
                                                            type="checkbox"
                                                            checked={item.isDefault}
                                                            onChange={(e) => handleCheckboxChange(index, e.target.checked)}
                                                        />
                                                    </div>
                                                </React.Fragment>
                                            ))}
                                        </div>
                                    ) : (
                                        <div className="text-center">No features were found</div>
                                    )}
                                </div>
                            </div>
                        </Row>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="d-flex justify-content-end">
                                <Button onClick={() => updateNotifyFeatureList()}>Update</Button>
                            </div>
                        </div>
                    </Container>
                </Card>
            </div>
        </>
    );
}

export default InAppFeatures;
