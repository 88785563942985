import React, { useState, useEffect } from "react";
import { Button, Card, Container, Row, Col, ToggleButton, ButtonGroup, Modal, Form, Table } from "react-bootstrap";
import { TiTick } from "react-icons/ti";
import { FaCircle } from "react-icons/fa";
import { LuRocket } from "react-icons/lu";
import { FaArrowRight } from "react-icons/fa6";
import { connect } from "react-redux";
import { useToast } from "../Toast";
import { format } from "date-fns";
import { useSubscription } from '../SubscriptionContext';
import { isBefore } from "date-fns";
import { useNavigate } from "react-router-dom";
import { useAuth } from '../AuthContext';
import { AiOutlineClose } from "react-icons/ai";
import loader from "../../assets/images/loader.gif";

import visaCard from '../../assets/images/Visa_Inc._logo.svg.png'
import mastercard from '../../assets/images/Mastercard-logo.svg.png'
import americancard from '../../assets/images/American_Express_logo_(2018).svg.webp'
import discovercard from '../../assets/images/Discover_Card_logo.svg.png'
import jcbcard from '../../assets/images/JCB_logo.svg.png'
import dinerscard from '../../assets/images/Diners_Club_Logo3.svg';

import SockJS from 'sockjs-client';
import { Client } from '@stomp/stompjs';


const SOCKET_URL = process.env.REACT_APP_BASEURL + "/ws";


const PlanDetails = ({ back, ...props }) => {
    const { login } = useAuth();
    const [billingCycle, setBillingCycle] = useState("monthly");
    const [planList, setPlanList] = useState([]);
    const [activePlanId, setActivePlanId] = useState(null);
    const [validity, setValidity] = useState(null);
    const [trialPlan, setTrialPlan] = useState("");
    const [trialStatus, setTrialStatus] = useState("");
    const [trialStartDate, setTrialStartDate] = useState(null);
    const [trialEndDate, setTrialEndDate] = useState("");
    const [planStatus, setPlanStatus] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [modalAction, setModalAction] = useState(null);
    const [pendingParams, setPendingParams] = useState(null);
    const [actions, setActions] = useState(null);
    const [planId, setPlanId] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const toast = useToast();
    const { refreshSubscriptions } = useSubscription();

    const [paymentData, setPaymentData] = useState([]);

    let stompClient = null;

    const [plan, SetPlan] = useState('')
    const [errors, setErrors] = useState({});

    const [eventType, setEventType] = useState("");


    const [paymentPopup, setPaymentPopup] = useState(false);
    const [paymentDetails, setPaymentDetails] = useState({
        creditCardNumber: "",
        expirationDate: "",
        cvvCode: "",
        amount: "1",
        holdername: ""
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setPaymentDetails({ ...paymentDetails, [name]: value });

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "",
        }));
    };

    // const handlePayment = async () => {
    //     setPaymentDetails({
    //         creditCardNumber: "",
    //         expirationDate: "",
    //         cvvCode: "",
    //         amount: "",
    //     });
    //     console.log("Payment details submitted:", paymentDetails);

    //     try {
    //         const response = await window.apiService.sendPayment(paymentDetails);
    //         console.log(response);

    //     } catch (error) {
    //         console.log(error);
    //     }
    //     // Integrate your payment API call here
    //     setPaymentPopup(false); // Close modal after submission
    // };


    const handlePayment = (e) => {


        e.preventDefault();


        const newErrors = {};

        // Check for empty fields
        if (!paymentDetails.creditCardNumber) {
            newErrors.creditCardNumber = "creditCardNumber required.";
        }
        if (!paymentDetails.expirationDate) {
            newErrors.expirationDate = "expirationDate required.";
        }
        if (!paymentDetails.cvvCode) {
            newErrors.cvvCode = "cvvCode required.";
        }

        setErrors(newErrors);


        const [expMonth, expYear] = paymentDetails.expirationDate.split("/");

        const secureData = {
            authData: {
                clientKey: process.env.REACT_APP_AUTHORIZE_CLIENTKEY,
                apiLoginID: process.env.REACT_APP_AUTHORIZE_LOGINKEY,
            },
            cardData: {
                cardNumber: paymentDetails.creditCardNumber,
                month: expMonth.trim(),
                year: expYear.trim(),
                cardCode: paymentDetails.cvvCode,
            },
        };


        if (window.Accept) {
            setLoading(true);
            window.Accept.dispatchData(secureData, async (response) => {
                if (response.messages.resultCode === "Error") {
                    console.error("Error:", response.messages.message);
                    const errorMessages = response.messages.message.map((msg) => msg.text).join(", ");
                    toast.error(errorMessages);
                    setLoading(false);
                } else {
                    const { dataDescriptor, dataValue } = response.opaqueData;
                    console.log("Tokenized Data:", response.opaqueData);

                    const requestData = {
                        dataDescriptor,
                        dataValue,
                        amount: paymentDetails.amount,
                        userId: props.userId,
                        planId: activePlanId,
                        holdername: paymentDetails.holdername,
                        paymentGateWay: "authorize.net"

                    };
                    try {
                        const paymentProcess = await window.apiService.sendPayment(requestData);
                        toast.success("Payment Processed");
                        const result = paymentProcess.data;

                        setPaymentDetails({
                            creditCardNumber: "",
                            expirationDate: "",
                            cvvCode: "",
                            amount: "",
                        });
                        console.log(result);
                        // addUserPlans(plan)
                        console.log("Payment Processed:", result);

                    } catch (error) {
                        console.error("Backend Error:", error);
                    } finally {
                        setLoading(false);
                        setPaymentPopup(false); // Close modal after submission
                    }
                }
            });
        } else {
            console.error("Accept.js not loaded.");
        }
    };

    useEffect(() => {
        loadPlans();
        getPlansById();
    }, []);

    const goBack = () => {
        back(false);
    };

    const loadPlans = async () => {
        setLoading(true);
        try {
            const response = await window.apiService.loadPlans();
            const allPlans = response.data.recordInfo;
            setPlanList(allPlans);
            const trialPlan = allPlans.find(plan => plan.isTrial === 1);
            setTrialPlan(trialPlan.servicePlan);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const getPlansById = async () => {
        if (props) {
            try {
                const response = await window.apiService.getPlanById(props.userId);
                if (response.data.status === "OK") {
                    setActivePlanId(response.data.recordInfo.planId);
                    setValidity(response.data.recordInfo.endDate);
                    setTrialStatus(response.data.recordInfo.trialStatus);
                    setTrialStartDate(response.data.recordInfo.trialStartDate);
                    setTrialEndDate(response.data.recordInfo.trialEndDate);
                    setPlanStatus(response.data.recordInfo.status);
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    const filteredPlans = planList.filter(plan => plan.billingCycle === billingCycle);
    const colors = ["#3498db", "#28a745", "#6f42c1", "#f1c40f", "#9b59b6"];

    const addUserPlans = async (plan, status) => {
        setLoading(true);
        console.log("Plan selected:", plan);

        const startDate = new Date();
        const endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + plan.planPeriod);

        const formattedStartDate = format(startDate, "yyyy-MM-dd");
        const formattedEndDate = format(endDate, "yyyy-MM-dd");

        const currentPlan = planList.find((p) => p.id === activePlanId);
        const currentPrice = currentPlan ? currentPlan.price : 0;

        const action = plan.price > currentPrice ? "upgraded" : "downgraded";
        setActions(action);
        setPlanId(plan.id);
        const params = {
            userId: props.userId,
            planId: plan.id,
            startDate: formattedStartDate,
            endDate: status == "trial" ? null : formattedEndDate,
            status: status == "trial" ? "none" : "active",
            action: status == "trial" ? status : action,
            actionScheduledDate: (status == "trial" || (action == "downgraded")) && new Date(validity) >= new Date() ? formattedStartDate : undefined,
            userPlanAction: (status == "trial" || (action == "downgraded")) && new Date(validity) >= new Date() ? (status == "trial" ? status : action) : undefined,
            actionPlanId: (status == "trial" || (action == "downgraded")) && new Date(validity) >= new Date() ? plan.id : undefined,
        };

        if (plan.trialPeriod && status) {
            const trialStart = new Date();
            const trialEnd = new Date(trialStart);
            trialEnd.setDate(trialStart.getDate() + plan.trialPeriod);

            params.trialStartDate = format(trialStart, "yyyy-MM-dd");
            params.trialEndDate = format(trialEnd, "yyyy-MM-dd");
            params.trialStatus = "active";
        }

        try {
            const response = await window.apiService.addUserPlan(params);
            if (response.status === "OK") {
                setLoading(false);
                toast.success(response.message);
                getPlansById();
                loadPlans();
                login(props.userId);
                refreshSubscriptions();
            }
        } catch (error) {
            setLoading(false);
            console.log("Error adding user plan:", error);
        }
        // if (action === "downgraded" && new Date(validity) > new Date() && trialPlan != currentPlan.servicePlan) {
        //     setPendingParams(params);
        //     setShowModal(true);
        //     return;
        // }

        // await executeApiCall(params);
    };

    const handleModalClose = () => {
        setShowModal(false);
        setPendingParams(null);
    };

    const executeApiCall = async (params) => {
        try {
            const response = await window.apiService.addUserPlan(params);
            if (response.status === "OK") {
                toast.success(response.message);
                getPlansById();
                loadPlans();
                login(props.userId);
                refreshSubscriptions();
            }
        } catch (error) {
            console.log("Error adding user plan:", error);
        }
    };

    const handleModalAction = (action) => {
        if (pendingParams) {
            if (action === "after") {
                pendingParams.actionScheduledDate = format(new Date(validity), "yyyy-MM-dd");
                pendingParams.userPlanAction = actions;
                pendingParams.actionPlanId = planId;
            } else if (action === "now") {
                pendingParams.action = "downgraded";
            }
            executeApiCall(pendingParams);
        }
        setShowModal(false);
    };

    const hasPlanExpired = () => {
        const validityDate = new Date(validity);
        const today = new Date();
        validityDate.setHours(0, 0, 0, 0);
        today.setHours(0, 0, 0, 0);

        return validityDate < today;
    };

    const getDiscountedPrice = (plan) => {
        const today = new Date();

        const applicableDiscount = plan.discount.find(discount => {
            const startDate = new Date(discount.startDate);
            const endDate = new Date(discount.endDate);
            return today >= startDate && today <= endDate;
        });

        if (applicableDiscount) {
            const { discountType, discountValue } = applicableDiscount;

            if (discountType === "percentage") {
                return plan.price * (1 - discountValue / 100);
            } else if (discountType === "fixed_amount") {
                return plan.price - discountValue;
            }
        }

        return plan.price;
    };

    const handlePlanClick = () => {
        // Set the plan price as the amount
        // setPaymentDetails({
        //     ...paymentDetails,
        //     amount: plan.price, // Set the plan's price as the amount
        // });
        setPaymentPopup(true); // Open the payment modal
    };


    const connectToWebSocket = () => {
        const socket = new SockJS(SOCKET_URL);
        stompClient = new Client({
            webSocketFactory: () => socket,
            reconnectDelay: 5000,
            debug: (str) => { console.log(str); },
            onConnect: () => {
                stompClient.subscribe(`/topic/webhook-events`, (messageOutput) => {
                    getPaymentStatus();
                    console.log("Received message:", messageOutput);
                });
            },
            onStompError: (error) => {
                console.error('STOMP error:', error);
            },
        });
        stompClient.activate();
    };



    const disconnectWebSocket = () => {
        if (stompClient !== null) {
            stompClient.deactivate();
        }
    };

    const updateUIWithEventType = (eventType) => {
        setEventType(eventType); // Update the state with the event type
    };

    const getPaymentStatus = async () => {
        try {
            const response = await window.apiService.paymentStatus(props.userId); // Fetch payment data for userId 72
            console.log(response)
            toast.success("Status received correctly");
            const data = response.data;
            setPaymentData(data);
            console.log(data)
        } catch (error) {
            console.log("Error fetching payment status:", error);
        }
    };

    useEffect(() => {
        connectToWebSocket();
        // getPaymentStatus();
        return () => {
            disconnectWebSocket();
        };
    }, []);

    // Helper function to get color for payment status
    const getStatusColor = (status) => {
        if (status === 'pending') {
            return 'badge-warning'; // Bootstrap's warning badge (yellow)
        }
        if (status === 'successful') {
            return 'badge-success'; // Bootstrap's success badge (green)
        }
        return 'badge-danger'; // Bootstrap's danger badge (red)
    };


    return (
        <div className="profile_div pln">
            <Container className="pb-0" fluid>

                <Row>
                    <Col className="order-xl-1" xl="12">
                        <Card className="bg-secondary profile_card pad_50">
                            <Row className="mb-4">

                                <div>
                                    <h1>Payment Status</h1>
                                    <p>{eventType ? `Event Type: ${eventType}` : "Waiting for webhook event..."}</p>
                                </div>
                                <Col lg="10">
                                    <h4>Choose your plan</h4>
                                    <p>Get the right plan for your business. Plans can be upgraded in the future.</p>
                                    {trialPlan && <span className="text-muted">
                                        <LuRocket /> Free {trialPlan} Plan
                                    </span>}
                                </Col>
                                <Col lg="2" className="text-end c_pointer">
                                    <span className="text-muted" onClick={goBack}>Back <FaArrowRight /> </span>
                                </Col>
                            </Row>

                            <Row className="justify-content-end">
                                <ButtonGroup className="mb-4" style={{ width: "20%" }}>
                                    <ToggleButton
                                        type="radio"
                                        name="billingCycle"
                                        value="monthly"
                                        checked={billingCycle === "monthly"}
                                        onClick={() => setBillingCycle("monthly")}
                                        className={billingCycle === "monthly" ? "btn_brown_checked" : "btn_brown_unchecked"}
                                    >
                                        Monthly
                                    </ToggleButton>
                                    <ToggleButton
                                        type="radio"
                                        name="billingCycle"
                                        value="yearly"
                                        checked={billingCycle === "yearly"}
                                        onClick={() => setBillingCycle("yearly")}
                                        className={billingCycle === "yearly" ? "btn_brown_checked" : "btn_brown_unchecked"}
                                    >
                                        Yearly
                                    </ToggleButton>
                                </ButtonGroup>
                            </Row>

                            <Row className="justify-content-center">
                                {filteredPlans && filteredPlans.length > 0 && !loading ? (
                                    (() => {
                                        const sortedPlans = [...filteredPlans].sort((a, b) => a.price - b.price);

                                        const displayedFeatures = new Set();
                                        let previousPlan = null;

                                        return sortedPlans.map((plan, idx) => {
                                            const includesPreviousPlan = previousPlan ? previousPlan.servicePlan : null;

                                            const renderedCard = (
                                                <Col key={idx} md="4" className="mb-4">
                                                    <Card className="plan-card">
                                                        {plan.id === activePlanId && (trialStatus != "active" ? (
                                                            <div className="ribbon">
                                                                {hasPlanExpired() && plan.isTrial == 0 ? "Expired" : "Active"}
                                                            </div>
                                                        ) : trialStatus == "active" ? <div className="ribbon"> Active
                                                        </div> : "")}
                                                        <Card.Header
                                                            className="bg-light"
                                                            style={{ color: colors[idx % colors.length] }}
                                                        >
                                                            <span>
                                                                <FaCircle className="icn_blur" />
                                                            </span>{" "}
                                                            {plan.servicePlan}
                                                            {plan.isTrial == 1 && <span className="freePln">Free</span>}
                                                        </Card.Header>
                                                        <Card.Body
                                                            className={
                                                                (plan.id === activePlanId && plan.isTrial == 0) || plan.trialPeriod && trialStartDate == null ? "pad_tp" : ""
                                                            }
                                                        >
                                                            <div className="d-flex justify-content-end">
                                                                {plan.trialPeriod && (plan.id != activePlanId || planStatus == "none") && (
                                                                    <div className={trialStartDate == null ? "freePln trl" : ""}>
                                                                        {trialStatus === "none" && trialStartDate == null ? (
                                                                            `${plan.trialPeriod} day${plan.trialPeriod > 1 ? "s" : ""} trial`
                                                                        ) : trialEndDate && plan.id === activePlanId ? (
                                                                            <div className="freePln">
                                                                                {new Date(trialEndDate).toDateString() === new Date().toDateString() ? (
                                                                                    "Trial expires today"
                                                                                ) : (
                                                                                    `Trial expires on ${format(new Date(trialEndDate), "dd MMM yyyy")}`
                                                                                )}
                                                                            </div>
                                                                        ) : ""}
                                                                    </div>
                                                                )}

                                                                {plan.id === activePlanId && plan.isTrial == 0 && planStatus == "active" ? (

                                                                    <div className="text-end fs_12 text-muted">
                                                                        {new Date(validity).toDateString() === new Date().toDateString() ? (
                                                                            <div className="freePln">Plan expires today</div>
                                                                        ) : hasPlanExpired() ? (
                                                                            "Plan Expired"
                                                                        ) : (
                                                                            `Valid till: ${format(new Date(validity), "dd MMM yyyy")}`
                                                                        )}
                                                                    </div>
                                                                ) : (
                                                                    <div></div>
                                                                )}
                                                            </div>
                                                            <h4>
                                                                {plan.isTrial === 1 ? (
                                                                    <s>${plan.price}</s>
                                                                ) : (
                                                                    <>
                                                                        {getDiscountedPrice(plan) < plan.price && (
                                                                            <>
                                                                                <s className="">${plan.price}</s> {" "}
                                                                                <span className="text-success">${getDiscountedPrice(plan).toFixed(2)}</span>

                                                                            </>
                                                                        )}
                                                                        {getDiscountedPrice(plan) === plan.price && (
                                                                            <span>${plan.price}</span>
                                                                        )}
                                                                    </>
                                                                )}
                                                                <small> / {billingCycle === "monthly" ? "month" : "year"}</small>
                                                            </h4>

                                                            <div className="text-muted fs_12">
                                                                {/* {includesPreviousPlan && (
                                                                    <div>
                                                                        <strong>(Includes {includesPreviousPlan} plan)</strong>
                                                                    </div>
                                                                )} */}
                                                                <strong>Subscription Duration:</strong>{" "}
                                                                {plan.isTrial == 0 ? `${plan.planPeriod} days` : "Unlimited"}
                                                            </div>
                                                            <div>{plan.description}</div>

                                                            <ul className="list-unstyled mt-3 mb-4 feat">
                                                                {includesPreviousPlan && (
                                                                    <li className="fs14">
                                                                        <TiTick className="tck" />
                                                                        <span className="uppercase fontBold fs_14px">
                                                                            Everything in {includesPreviousPlan}
                                                                        </span>
                                                                    </li>
                                                                )}

                                                                {plan.features
                                                                    .filter((feature) => {
                                                                        const isDisplayed = displayedFeatures.has(
                                                                            feature.featureName
                                                                        );
                                                                        if (!isDisplayed) {
                                                                            displayedFeatures.add(feature.featureName);
                                                                            return true;
                                                                        }
                                                                        return false;
                                                                    })
                                                                    .map((feature, featureIdx) => (
                                                                        <li key={featureIdx} className="fs14">
                                                                            <TiTick className="tck" /> {feature.featureName}{" "}
                                                                            {/* <span className="text-muted">
                                                                                ({feature.description})
                                                                            </span> */}
                                                                        </li>
                                                                    ))}
                                                            </ul>
                                                            {plan.isTrial == 0 && trialStatus != "active" &&
                                                                (!hasPlanExpired() || plan.id !== activePlanId) ? (

                                                                <>
                                                                    <div className="mt-auto">
                                                                        {plan.trialPeriod && (plan.id != activePlanId || planStatus == "none") && trialStartDate == null && trialEndDate == null && <div className="mar_bt_8"><Button onClick={() => addUserPlans(plan, "trial")}
                                                                            disabled={trialStatus !== "none"}
                                                                            className={
                                                                                trialStatus == "active"
                                                                                    ? "btn btn_plan_act w_100"
                                                                                    : "btn btn_plan w_100"
                                                                            }
                                                                        >
                                                                            {trialStatus == "active" && plan.id === activePlanId && trialStartDate && trialEndDate ? (
                                                                                <>
                                                                                    Trial Activated
                                                                                </>
                                                                            ) : (
                                                                                "Get Trial"
                                                                            )}
                                                                        </Button></div>}
                                                                        <Button
                                                                            onClick={() => {
                                                                                addUserPlans(plan); // Call your function
                                                                                // setPaymentPopup(true); // Open the modal

                                                                                // handlePlanClick(plan)
                                                                            }}
                                                                            disabled={plan.id === activePlanId}
                                                                            className={
                                                                                plan.id === activePlanId
                                                                                    ? "btn btn_plan_act w_100"
                                                                                    : "btn_plan w_100"
                                                                            }
                                                                        >
                                                                            {plan.id === activePlanId ? "Active Plan" : "Get Plan"}
                                                                        </Button>

                                                                    </div>
                                                                </>
                                                            ) : plan.isTrial == 0 && trialStatus == "none" ? (
                                                                <Button
                                                                    onClick={() =>
                                                                        navigate("/dashboard/user_settings/plans_billings")
                                                                    }
                                                                    className={
                                                                        plan.id === activePlanId
                                                                            ? "btn btn_plan_act w_100"
                                                                            : "btn_plan w_100"
                                                                    }
                                                                >
                                                                    Renew plan
                                                                </Button>
                                                            ) : trialStatus == "active" ? (
                                                                <Button
                                                                    onClick={() => {
                                                                        addUserPlans(plan); // Call your function
                                                                        // setPaymentPopup(true); // Open the modal

                                                                        // handlePlanClick(plan)
                                                                    }}

                                                                    className="btn_plan w_100"
                                                                >
                                                                    Get Plan
                                                                </Button>
                                                            ) : ""}
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            );

                                            previousPlan = plan;
                                            return renderedCard;
                                        });
                                    })()
                                ) : loading ? (
                                    <div className="loader-center">
                                        <img className="loader-image" src={loader} alt="Loading..." />
                                    </div>
                                ) : (
                                    <div className="row d-align mx-0 pb-4">
                                        <div className="no-data font-weight-600 w-100 text-center fz_14">
                                            Sorry, there is no data to be displayed
                                        </div>
                                    </div>
                                )}

                                <Col md="4" className="mb-4">
                                    <Card className="plan-card">
                                        <Card.Header className="bg-light">
                                            <span>
                                                <FaCircle className="icn_blur" />
                                            </span>{" "}
                                            Enterprise
                                        </Card.Header>
                                        <Card.Body>
                                            <h6 className="text-muted">Enterprise Plan</h6>
                                            <div><span >

                                                <ul className="list-unstyled mt-3 mb-4 feat">
                                                    <li className="uppercase fontBold fs_14px">
                                                        <TiTick className="tck" /> Everything in Business Plus
                                                    </li>
                                                    <li className="fs14">
                                                        <TiTick className="tck" /> Additional features
                                                    </li>

                                                </ul>

                                            </span></div>
                                            <div>
                                                <div><span className="font-weight-bold">contact</span> support@tiipstr.com</div>
                                                <div><span className="font-weight-bold">phone</span> 180 xxx xxx</div>
                                                <div>contact supporting team for plan and pricing details</div>
                                            </div>
                                            {/* <Button className="btn_plan w_100">Get Plan</Button> */}
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>


                        </Card>
                    </Col>
                </Row>
            </Container>

            <Button onClick={handlePlanClick}>Payment Testing</Button>

            {/* ----------- confirm degrade plan before expiry ---------- */}
            <Modal
                size="sm"
                show={showModal}
            >
                <Modal.Header>
                    <Modal.Title
                        id="example-custom-modal-styling-title"
                        style={{ fontSize: "15px", fontWeight: 600 }}
                    >
                        Confirm Plan Downgrade
                    </Modal.Title>
                    <span onClick={handleModalClose} className="c_pointer" title="Close">
                        <AiOutlineClose />
                    </span>
                </Modal.Header>
                <Modal.Body className="bordr">

                    <p>
                        Would you like the new plan to start after the current one expires, or would you prefer it to start right away?
                    </p>
                </Modal.Body>

                <Modal.Footer className="bordr pinBtm">
                    <Button variant="primary"
                        className="del_bttn" onClick={() => handleModalAction("after")}>
                        After Current Plan
                    </Button>
                    <Button variant="danger"
                        className="del_bttn" onClick={() => handleModalAction("now")}>
                        Start Now
                    </Button>

                </Modal.Footer>
            </Modal>


            <Modal size="sm" show={paymentPopup} onHide={() => setPaymentPopup(false)}>
                <Modal.Header className="d-flex justify-content-center align-items-center position-relative">
                    <Modal.Title style={{ fontSize: "25px", fontWeight: 600 }}>
                        Payment Details
                    </Modal.Title>
                    <span
                        onClick={() => setPaymentPopup(false)}
                        title="Close"
                        className="c_pointer position-absolute end-0 me-3"
                    >
                        <AiOutlineClose />
                    </span>
                </Modal.Header>

                <Modal.Body>
                    <div className="card-logos d-flex justify-content-between align-items-center flex-wrap mb-4">
                        <img
                            src={visaCard}
                            alt="Visa"
                            title="Visa"
                            className="card-logo img-fluid"
                            style={{ height: "15px", width: "auto" }}
                        />
                        <img
                            src={mastercard}
                            alt="MasterCard"
                            title="MasterCard"
                            className="card-logo img-fluid"
                            style={{ height: "30px", width: "auto" }}
                        />
                        <img
                            src={americancard}
                            alt="American Express"
                            title="American Express"
                            className="card-logo img-fluid"
                            style={{ height: "30px", width: "auto" }}
                        />
                        <img
                            src={discovercard}
                            alt="Discover"
                            title="Discover"
                            className="card-logo img-fluid"
                            style={{ height: "10px", width: "auto" }}
                        />
                        <img
                            src={dinerscard}
                            alt="Diners Club"
                            title="Diners Club"
                            className="card-logo img-fluid"
                            style={{ height: "20px", width: "auto" }}
                        />
                        <img
                            src={jcbcard}
                            alt="JCB"
                            title="JCB"
                            className="card-logo img-fluid"
                            style={{ height: "30px", width: "auto" }}
                        />
                    </div>


                    <Form>
                        <Form.Group controlId="holdername">
                            <Form.Label>Card Holder name</Form.Label>
                            <Form.Control
                                type="text"
                                name="holdername"
                                value={paymentDetails.holdername}
                                onChange={handleInputChange}
                                placeholder="holderName"
                            />
                            {errors.creditCardNumber && (
                                <div className="text-danger mt-1">{errors.creditCardNumber}</div>
                            )}
                        </Form.Group>
                        <Form.Group controlId="creditCardNumber">
                            <Form.Label className="mt-3">Credit Card Number</Form.Label>
                            <Form.Control
                                type="text"
                                name="creditCardNumber"
                                value={paymentDetails.creditCardNumber}
                                onChange={handleInputChange}
                                placeholder="4111 1111 1111 1111"
                            />
                            {errors.creditCardNumber && (
                                <div className="text-danger mt-1">{errors.creditCardNumber}</div>
                            )}
                        </Form.Group>
                        <Form.Group controlId="expirationDate" className="mt-3">
                            <Form.Label>Expiration Date (MM/YY)</Form.Label>
                            <Form.Control
                                type="text"
                                name="expirationDate"
                                value={paymentDetails.expirationDate}
                                onChange={handleInputChange}
                                placeholder="12/24"
                            />
                            {errors.expirationDate && (
                                <div className="text-danger mt-1">{errors.expirationDate}</div>
                            )}
                        </Form.Group>
                        <Form.Group controlId="cvvCode" className="mt-3">
                            <Form.Label>CVV Code</Form.Label>
                            <Form.Control
                                type="text"
                                name="cvvCode"
                                value={paymentDetails.cvvCode}
                                onChange={handleInputChange}
                                placeholder="123"
                            />
                            {errors.cvvCode && (
                                <div className="text-danger mt-1">{errors.cvvCode}</div>
                            )}
                        </Form.Group>
                        {/* <Form.Group controlId="amount" className="mt-3">
                            <Form.Label>Amount</Form.Label>
                            <Form.Control
                                type="number"
                                name="amount"
                                value={paymentDetails.amount}
                                onChange={handleInputChange}
                                placeholder="$1"
                            />
                        </Form.Group> */}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handlePayment}>
                        Pay Now
                    </Button>
                    <Button variant="danger" onClick={() => setPaymentPopup(false)}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>

            <div className="profile_div pln">
                <Container>
                    <Row>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <h5>Payment Status</h5>

                                    {/* Table to display payment data */}
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Payment Status</th>
                                                <th>Payment Amount</th>
                                                <th>Transaction ID</th>
                                                <th>User ID</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {paymentData.map((payment) => (
                                                <tr key={payment.id}>
                                                    <td>
                                                        <span className={`badge ${getStatusColor(payment.paymentStatus)}`}>
                                                            {payment.paymentStatus}
                                                        </span>
                                                    </td>
                                                    <td>{payment.paymentAmount}</td>
                                                    <td>{payment.transactionId}</td>
                                                    <td>{payment.userId}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>


        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userId: state.user.userId,
    };
};
export default connect(mapStateToProps)(PlanDetails);
