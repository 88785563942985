import { createContext, useContext, useEffect, useState, useCallback } from 'react';
import { useAuth } from './AuthContext';

const SubscriptionContext = createContext();

export const useSubscription = () => useContext(SubscriptionContext);

export const SubscriptionProvider = ({ children }) => {
  const [features, setFeatures] = useState([]);
  const [allFeatures, setAllFeatures] = useState({});
  const [endDate, setEndDate] = useState(null);
  const [planId, setPlanId] = useState(null);
  const [refreshKey, setRefreshKey] = useState(1);
  const { userId } = useAuth();

  const refreshSubscriptions = useCallback(() => {
    setRefreshKey((prevKey) => prevKey + 1);
  }, []);

  useEffect(() => {
    if (userId) {
      const loadFeatureById = async () => {
        try {
          const response = await window.apiService.userPlanFeaturesList(userId);
          const fetchedFeatures = response.data.data.features || [];
          setFeatures(fetchedFeatures);
          setEndDate(response.data.data.endDate);
          setPlanId(response.data.data.planId);
          localStorage.setItem("planFeatures", JSON.stringify(fetchedFeatures));
        } catch (error) {
          console.error("Error fetching user plan features:", error);
        }
      };

      loadFeatureById();
    }
  }, [userId, refreshKey]);

  useEffect(() => {
    const loadAllPlanFeatures = async () => {
      try {
        const response = await window.apiService.loadAllFeatures();
        const featureMap = response.data.recordInfo.reduce((acc, feature) => {
          acc[feature.key] = {
            featureName: feature.featureName,
            id: feature.id,
            key: feature.key,
            isDeleted: feature.isDeleted
          };
          return acc;
        }, {});
        setAllFeatures(featureMap);
      } catch (error) {
        console.error("Error fetching all features:", error);
      }
    };

    loadAllPlanFeatures();
  }, [refreshKey]);

  const isFeatureEnabled = (featureKey,isDeleted) => {
    let featuresList = features;

    if (!featuresList.length) {
      try {
        featuresList = JSON.parse(localStorage.getItem("planFeatures")) || [];
      } catch (error) {
        console.error(error);
      }
    }
   return featuresList.some((feature) => {
  const isKeyMatch = feature.key === featureKey;
  const isDeletedd = isDeleted === 1;
  return isKeyMatch || isDeletedd;
});

    
  };

  return (
    <SubscriptionContext.Provider value={{ features, allFeatures, endDate, planId, isFeatureEnabled, refreshSubscriptions }}>
      {children}
    </SubscriptionContext.Provider>
  );
};
