

export const userAction = (data) => (
  {
  type: 'SAVE_USER_DATA',
  payload: {
    token: data.token,
    userId: data.userId,
    userType: data.userType,
    userName: data.name,
    imagePath: (data.imagePath != null ? data.imagePath : ''),
    newReviewCount: data.newReviewCount || 0,
    newInboxCount: data.newInboxCount || 0,
    groupNotify: data.groupNotify,
    newMentionCount: data.newMentionCount || 0,
    emailVerified: data.emailVerified,
    phoneVerified: data.phoneVerified,
    userTypeInfo: data.userTypeInfo,
    email: data.email,
    phone: (data.phone != undefined ? data.phone : ''),
    profileId: data.profileId,
    isKycVerified: data.isKycVerified,
    planNotifyCount: data.planNotifyCount,
    isAddUserInApp: data.isAddUserInApp,
    isInboxInApp: data.isInboxInApp,
    isInviteUserInApp: data.isInviteUserInApp,
    isMentionCommentInApp: data.isMentionCommentInApp,
    isPlanExpInApp: data.isPlanExpInApp,
    isProfileUpdateInApp: data.isProfileUpdateInApp,
    isReviewCommentInApp: data.isReviewCommentInApp,
    isReviewInApp: data.isReviewInApp,
    isTrialExpInApp: data.isTrialExpInApp
  }
});

export const userAuth = (isAuthenticating) => ({
  type: 'USER_AUTH',
  payload: {
    isAuthenticating: isAuthenticating,
  }
});

export const logoutAction = () => ({
  type: 'LOGOUT',
});

// Admin

export const userTypeId = (selectedTypeId) => {
  return {
    type: 'USER_TYPE_ID',
    payload: {
      selectedTypeId,
    },
  };
};

export const followId = (followid, userType, profileId) => {
  return {
    type: 'FOLLOW_ID',
    payload: {
      followid,
      userType,
      profileId
    },
  };
};
export const notifyRevInfo = (revInfo) => ({
  type: 'NOT_REV_INFO',
  payload: {
    id: revInfo.id,
  }
});
export const revId = (revId) => {
  return {
    type: 'REV_ID',
    payload: {
      revId,
    },
  };
};
export const phoneNumber = (phNumber) => {
  return {
    type: 'PH_NUMBER',
    payload: {
      phNumber,
    },
  };
};

export const setNotification = (notification) => ({
  type: 'SET_NOTIFICATION',
  payload: notification,
});

export const clearNotifications = () => ({
  type: 'CLEAR_NOTIFICATIONS',
});


export const setReviewNotification = (notification) => ({
  type: 'SET_Review_NOTIFICATION',
  payload: notification,
});

export const clearReviewNotifications = () => ({
  type: 'CLEAR_Review_NOTIFICATIONS',
});

export const incrementNotificationCount = () => ({
  type: 'INCREMENT_NOTIFICATION_COUNT',
});

export const resetNotificationCount = () => ({
  type: 'RESET_NOTIFICATION_COUNT',
});

export const setMentionNotification = (mentionnotification) => ({
  type: 'SET_MENTION_NOTIFICATION',
  payload: mentionnotification,
});


export const resetMentionNotificationCount = () => ({
  type: 'RESET_MENTION_NOTIFICATION_COUNT',
});

export const removeMentionNotificationByIndex = (index) => ({
  type: 'REMOVE_MENTION_NOTIFICATION',
  payload: index,
});


