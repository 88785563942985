import React, { useState, useRef, useEffect } from "react";
import { Card, Button, Col, Modal, Row } from "react-bootstrap";
import CustomModal from "../../plugins/CustomModal";
import { BsImages } from "react-icons/bs";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { BiImageAdd } from "react-icons/bi";
import loader from "../../../assets/images/loader.gif";
import profile from "../../../assets/images/profile.jpg";
import { useForm } from "react-hook-form";
import { useToast } from "../../Toast";
import PostCard from "./PostCard";
import { BsPlusLg } from "react-icons/bs";
import { connect } from "react-redux";
import StarRating from "./StarRating";
import { useNavigate } from "react-router-dom";
import { IoCloseCircleSharp } from "react-icons/io5";
import { AiOutlineClose } from "react-icons/ai";
import AddPost from "./AddPost";
import { IoIosArrowDown } from "react-icons/io";
import { Overlay, Popover, OverlayTrigger } from "react-bootstrap";
import Horizontalbar from "../../plugins/Horizontalbar";
import { FiUsers, FiUser } from "react-icons/fi";
import { commonUtils } from "../../CommonUtils";

const TiipTokTab = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [postList, setPostList] = useState([]);
  const [frndList, setFriendList] = useState([]);
  const [data, setData] = useState([]);
  const [deletePopupShow, setDeletePopupShow] = useState(false);
  const [revGlobalRtng, setRevGlobalRtng] = useState([]);
  const [avgCount, setAvgCount] = useState("");
  const [currentUsers, setCurrentUsers] = useState([]);
  const toast = useToast();
  const navigate = useNavigate();
  const [config, setConfig] = useState([]);

  const handleClose = () => setDeletePopupShow(false);

  useEffect(() => {
    loadPostList();
    friendList();
    fetch("/config.json")
      .then((res) => res.json())
      .then((res) => {
        setConfig(res);
      });
  }, [props.userType]);
  useEffect(() => {
    const reloadPostListHandler = () => {
      loadPostList();
    };
    window.addEventListener("reloadPostList", reloadPostListHandler);
    return () => {
      window.removeEventListener(
        "reloadPostList",
        reloadPostListHandler
      );
    };
  }, []);

  const loadPostList = async () => {
    const params = {
      userType: props.userType,
      profileId: props.profileId,
    };
    try {
      const response = await window.apiService.postList(params);
      setPostList(response.data.data);
      console.log("response", response.data.data);
    } catch (error) {

      toast.error("Error Occurred: " + error.message);
    }
  };
  const friendList = async () => {
    const params = {
      receiverId: props.userId,
      receiverUserType: props.userType,
      receiverTypeId: props.profileId ? props.profileId : null
    };
    try {
      const response = await window.apiService.friendList(params);
      setFriendList(response.data.data);
      console.log("response", response.data.data);
    } catch (error) {

      toast.error("Error Occurred: " + error.message);
    }
  };

  const viewuser = (data) => {
    navigate("/dashboard/viewuser", {
      state: {
        userId: data.id,
        userType: data.businessId ? "Business" : "Personal",
        profileId: data.businessId ? data.businessId : null,
      },
    });

  };

  const showDeletePopup = (data) => {
    setData(data);
    setDeletePopupShow(true);
  };

  const RemoveFrnd = async () => {
    if (data) {
      const params = {
        senderId: data.id,
        senderUserType: data.businessInfo ? "Business" : "Personal",
        senderTypeId: data.businessInfo ? data.businessInfo.id : null,
        receiverId: props.userId,
        receiverUserType: props.userType,
        receiverTypeId: props.profileId ? props.profileId : null
      };

      try {
        const response = await window.apiService.removeFriend(params);
        toast.success(response.data.message);
        friendList();
        setDeletePopupShow(false);
      } catch (error) {

        setDeletePopupShow(false);
        toast.error("Error Occurred: " + error.message);
      }
    }
  };
  const handleArrowClick = (rating, avgCount) => {
    setAvgCount(avgCount);
    const starResponse = rating;
    const result = starResponse.map((starData) => ({
      label: starData.star,
      value: starData.totalReviewCount,
      userRatings: starData.userRatings,
    }));
    result.sort((a, b) => {
      const numA = parseInt(a.label.split(" ")[0]);
      const numB = parseInt(b.label.split(" ")[0]);
      return numB - numA;
    });
    setRevGlobalRtng(result);
  };
  const handleMouseEnter = (users) => {
    if (users.length > 0) setCurrentUsers(users);
    else setCurrentUsers([]);
  };
  return (
    <>
      <div className=" d-flex row ">
        <div className="col-sm-3 col-md-3 pr_0">
          <div className="d-flex frd_list_div gp_10 m-2">
            <Card className="m-2 me_revList mx_h_45vh" style={{ width: "100%" }}>
              <div className="frndList">Friends</div>
              {frndList && frndList.length > 0
                ? frndList.map((data, i) => (
                  <Card className="rounded-0 px-2 py-2">

                    <div className="d-flex align-items-center">
                      <Col lg="4" md="4" sm="2" xl="4">
                        {data.businessInfo ? <img
                          className="brd_rad"
                          width="40"
                          height="40"
                          src={
                            data.businessInfo.logo
                              ? data.businessInfo.logo
                              : profile
                          }
                        /> : <img
                          className="brd_rad"
                          width="40"
                          height="40"
                          src={
                            data.imagePath
                              ? data.imagePath
                              : profile
                          } />}
                      </Col>
                      <Col lg="8" md="8" sm="8" xl="8">
                        <div className="d-flex">
                          <div className="d-flex flex-column c_pointer"><div className="d-flex"><p className="mb-0 font-weight-400 fz_14 name_blue" onClick={() => viewuser(data)}>
                            {data.businessInfo?.companyName ? data.businessInfo.companyName : `${data.firstName} ${data.lastName ? data.lastName :""}`}
                          </p> <span title="Remove" className="c_pointer clr_red rmve_frnd" onClick={() => showDeletePopup(data)}><IoCloseCircleSharp /></span></div>
                            <div className="d-flex">{data.averageReviewScore > 0.0 ? (
                              <label className="align-items-center d-flex m-0 fs_12">
                                {data.businessInfo ? data.businessInfo.averageReviewScore.toFixed(1) : data.averageReviewScore.toFixed(1)}
                              </label>
                            ) : (
                              ""
                            )}<StarRating
                                rating={data.businessInfo?.averageReviewCount ? data.businessInfo.averageReviewCount : data.averageReviewCount}
                                disabled={true}
                                customClass="ques-star"
                              />
                              <span className="align-items-center d-flex fs_12">({data.businessInfo ? data.businessInfo.totalReviewCount : data.totalReviewCount})</span>
                              <OverlayTrigger
                                trigger="click"
                                key={data.id}
                                placement="bottom"
                                overlay={
                                  <Popover
                                    id={`popover-${data.id}`}
                                    className="pop_rating"
                                  >
                                    <div className="drp_rating">
                                      <StarRating rating={avgCount} disabled={true} />
                                      <span>{avgCount} out of 5</span>{" "}
                                    </div>
                                    {revGlobalRtng.map((item, index) => (
                                      <Row key={index}>
                                        <Col sm={12} md={3} lg={3} xl={2} className="p-0">
                                          <span className="prog_span">{item.label}</span>{" "}
                                        </Col>
                                        <Col sm={12} md={8} lg={8} xl={7} className="p-0">
                                          <Horizontalbar dataSet={item.value} />
                                        </Col>
                                        <Col
                                          sm={12}
                                          md={3}
                                          lg={3}
                                          xl={2}
                                          className="p-0 prog_span"
                                          style={{ marginLeft: "10px" }}
                                        >
                                          <span className="tooltip">
                                            <span
                                              className="c_pointer"
                                              onMouseEnter={() =>
                                                handleMouseEnter(item.userRatings)
                                              }
                                            >
                                              {item.value}
                                              {item.value > 1 ? (
                                                <FiUsers style={{ marginLeft: "6px" }} />
                                              ) : (
                                                <FiUser style={{ marginLeft: "6px" }} />
                                              )}
                                            </span>
                                            {commonUtils.ratingTooltip(currentUsers)}
                                          </span>
                                        </Col>
                                      </Row>
                                    ))}
                                  </Popover>
                                }
                                rootClose
                              >
                                <span
                                  className="d-flex align-items-center c_pointer"
                                  onClick={() =>
                                    handleArrowClick(
                                      data.businessInfo ? data.businessInfo.starRating : data.starRating,
                                      data.businessInfo ? data.businessInfo.averageReviewCount : data.averageReviewCount
                                    )
                                  }
                                >
                                  <IoIosArrowDown className="clr_gray fs_12" />
                                </span>
                              </OverlayTrigger>

                            </div></div>
                        </div>

                      </Col>
                    </div>
                  </Card>
                ))
                : <div className="pad_12">No Friends found</div>}
            </Card>

          </div>
        </div>
        <div className="col-sm-6 col-md-6">
          <AddPost props={props} />
          <div className="row col-md-12 p-2 mx-0 flx_column">
            {postList && postList.length > 0 ? (
              postList.map((post, idx) => (
                <PostCard
                  postInfo={post}
                  key={idx}
                  userId={props.userId}
                  loadPostList={loadPostList}
                />
              ))
            ) : (
              <div className="row d-align mx-0 pb-4">
                <div className="no-data font-weight-600 w-100 text-center fz_14">
                  Sorry, there is no data to be displayed
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* ----------- Delete Confirmation Popup ---------- */}
      <Modal
        size="sm"
        show={deletePopupShow}
        onHide={() => setDeletePopupShow(false)}
      >
        <Modal.Header>
          <Modal.Title
            id="example-custom-modal-styling-title"
            style={{ fontSize: "15px", fontWeight: 600 }}
          >
            Delete Confirmation
          </Modal.Title>
          <span onClick={handleClose} title="Close">
            <AiOutlineClose />
          </span>
        </Modal.Header>
        <Modal.Body className="bordr">
          <span>Are you sure you want to remove the friend?</span>
        </Modal.Body>
        <Modal.Footer className="bordr">
          <Button
            variant="primary"
            className="del_bttn"
            onClick={RemoveFrnd}
          >
            Yes
          </Button>
          <Button
            variant="danger"
            className="del_bttn"
            onClick={() => setDeletePopupShow(false)}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>

    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    userType: state.user.userType,
    profileId: state.user.profileId,
  };
};
export default connect(mapStateToProps)(TiipTokTab);
